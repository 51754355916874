import {createAction, handleActions} from 'redux-actions';
import {reducerMethods} from './redux';

export const defaultState = {
  rows: [],
};

export const updateInterviewListState = createAction('UPDATE_INTERVIEW_LIST_STATE');

export const reducer = handleActions(
  {
    [updateInterviewListState]: reducerMethods.update,
  },
  defaultState,
);
