import axios from 'axios';
import {createAction, handleActions} from 'redux-actions';
import {reducerMethods} from './redux';
import {handleError} from '../lib/lib/handle-error';

import ruTranslationsList from '../constants/translations/ru.json';

export const getCountries = search => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    const options = {
      method: 'GET',
      url: '/api/utils/country',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    };

    const {data} = await axios({...options, params: {q: search}});

    await dispatch(
      updateDirectoriesState({
        countries: data,
      }),
    );
  } catch (error) {
    handleError(error);
  }
};

export const getRegions = search => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    const options = {
      method: 'GET',
      url: '/api/utils/region',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    };

    const {data} = await axios({...options, params: {q: search}});

    await dispatch(
      updateDirectoriesState({
        regions: data,
      }),
    );
  } catch (error) {
    handleError(error);
  }
};

export const getCity = search => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    const options = {
      method: 'GET',
      url: '/api/utils/city',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    };

    const {data} = await axios({...options, params: {q: search}});

    await dispatch(
      updateDirectoriesState({
        cities: data,
      }),
    );
  } catch (error) {
    handleError(error);
  }
};

export const getFunctions = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
    directories: {
      translations,
    },
  } = getState();

  try {
    const options = {
      method: 'GET',
      url: '/api/function',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    };

    let transl = translations;

    const {data} = await axios({...options});

    if (!transl || !Object.keys(transl).length) {
      transl = await dispatch(getTranslations());
    }

    await dispatch(
      updateDirectoriesState({
        functions: data.map(el => ({
          ...el,
          value: transl[el.value],
        })),
      }),
    );
  } catch (error) {
    handleError(error);
  }
};

export const getMedicalRestrictions = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
    directories: {
      translations
    }
  } = getState();

  try {
    const options = {
      method: 'GET',
      url: '/api/medical-restrictions',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    };

    let transl = translations;

    const {data} = await axios({...options});

    if (!transl || !Object.keys(transl).length) {
      transl = await dispatch(getTranslations());
    }

    await dispatch(
      updateDirectoriesState({
        medicalRestrictions: data.map(el => ({
          ...el,
          value: transl[el.value]
        })),
      }),
    );
  } catch (error) {
    handleError(error);
  }
};

export const getClothesSize = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    const options = {
      method: 'GET',
      url: '/api/clothes-size',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    };

    const {data} = await axios({...options});

    await dispatch(
      updateDirectoriesState({
        clothesSize: data,
      }),
    );
  } catch (error) {
    handleError(error);
  }
};

export const getLanguage = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
    directories: {
      translations
    }
  } = getState();

  try {
    const options = {
      method: 'GET',
      url: '/api/language',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    };

    let transl = translations;

    const {data} = await axios({...options});

    if (!transl || !Object.keys(transl).length) {
      transl = await dispatch(getTranslations());
    }

    await dispatch(
      updateDirectoriesState({
        languages: data.map(el => ({
          ...el,
          value: transl[el.value]
        })),
      }),
    );
  } catch (error) {
    handleError(error);
  }
};

export const getLanguageProficiency = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
    directories: {
      translations
    }
  } = getState();

  try {
    const options = {
      method: 'GET',
      url: '/api/language-proficiency',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    };

    let transl = translations;

    const {data} = await axios({...options});

    if (!transl || !Object.keys(transl).length) {
      transl = await dispatch(getTranslations());
    }

    await dispatch(
      updateDirectoriesState({
        languageLevel: data.map(el => ({
          ...el,
          value: transl[el.value]
        })),
      }),
    );
  } catch (error) {
    handleError(error);
  }
};

export const getEducations = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    const options = {
      method: 'GET',
      url: '/api/education',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    };

    const {data} = await axios({...options});

    await dispatch(
      updateDirectoriesState({
        educations: data,
      }),
    );
  } catch (error) {
    handleError(error);
  }
};

export const getTranslations = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    const options = {
      method: 'GET',
      url: '/api/i18n',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        LANG: 'ru',
      },
    };

    const {data} = await axios({...options});

    await dispatch(
      updateDirectoriesState({
        translations: data,
      }),
    );
    return data;
  } catch (error) {
    handleError(error);
    return {};
  }
};

export const defaultState = {
  countries: [],
  regions: [],
  cities: [],
  functions: [],
  medicalRestrictions: [],
  clothesSize: [],
  languages: [],
  languageLevel: [],
  educations: [],
  translations: {},
};

export const resetDirectoriesState = () => dispatch => dispatch(updateDirectoriesState(defaultState));

export const updateDirectoriesState = createAction('UPDATE_DIRECTORIES_STATE');

export const reducer = handleActions(
  {
    [updateDirectoriesState]: reducerMethods.update,
  },
  defaultState,
);
