import {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    background: #64B09C;
    height: 100%;
    margin: 0;
  }

  html {
    height: 100%;
  }
  #app {
    height: 100%;
  }

  article,
  aside,
  footer,
  header,
  nav,
  section {
    display: block;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
`;
