import axios from 'axios';
import {createAction, handleActions} from 'redux-actions';
import get from 'lodash/get';
import Cookies from 'js-cookie';
import {handleError} from '../lib/lib/handle-error';
import {addErrorNotification} from './notifications';
import {reducerMethods} from './redux';
import {history} from '../modules/store';

import NoAvatar from '../img/no-avatar.png';
import {updateStaffState} from './staff';

export const getUtilsLanguages = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'GET',
        url: '/api/language',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        /*
         * Params: {
         *   countryId: '7',
         * },
         */
      };

      const {data} = await axios(options);
      await dispatch(updateUtilsState({languages: data}));
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      history.push('/');
      await dispatch(
        addErrorNotification({
          title: 'Ошибка',
          message: 'Нет прав доступа',
        }),
      );
      return false;
    }
    if (statusCode === 401) {
      history.push('/');
      return false;
    }
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const getFile = (fileName, url) => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'GET',
        url: `/api/static${url}`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        responseType: 'blob',
      };

      const {data} = await axios(options);

      const fileUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');

      link.href = fileUrl;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);

      link.click();
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      history.push('/');
      return false;
    }
    if (statusCode === 401) {
      history.push('/');
      return false;
    }
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const getBlobFile = (fileName, url) => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'GET',
        url: `/api/static${url}`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        responseType: 'blob',
      };

      const {data} = await axios(options);

      const file1 = new File([data], fileName);

      const fileUrl = window.URL.createObjectURL(file1);

      return fileUrl;
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const getPhoto = url => async (dispatch, getState) => {
  if (!url) {
    return '';
  }

  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'GET',
        url: `/api/static${url}`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        responseType: 'blob',
      };

      const {data} = await axios(options);

      const fileUrl = window.URL.createObjectURL(new Blob([data]));

      return fileUrl;
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
  return '';
};

export const getBase64 = url => async (dispatch, getState) => {
  if (!url) {
    const img = new Image();
    img.src = NoAvatar;

    return new Promise(resolve => {
      img.onload = function() {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let dataURL = '';
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL();
        resolve(dataURL);
      };
    });
  }

  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'GET',
        url: `/api/static${url}`,
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        responseType: 'blob',
      };
      const {data} = await axios(options);
      const reader = new FileReader();

      reader.readAsDataURL(new Blob([data]));

      return new Promise(resolve => {
        reader.onloadend = function() {
          resolve(reader.result);
        };
      });
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
  return '';
};

export const uploadEmployeeDocumentFile = (id, name, source) => async (dispatch, getState) => {
  try {
    const {
      auth: {
        tokenInfo: {access_token},
      },
    } = getState();

    const params = {
      headers: {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    const file = new Blob([source], {type: source.type});
    const formData = new FormData();
    const ext = source.name.split('.').pop();

    formData.append('name', source.name);
    formData.append('ext', ext);
    formData.append('file', file);

    try {
      await dispatch(updateUtilsState({loading: true}));

      const {data} = await axios.post(`/api/manager-employee/volunteer/${id}/upload/${name}`, formData, params);

      await dispatch(
        updateUtilsState({
          loading: false,
        }),
      );
      await dispatch(
        updateStaffState({
          staffItem: data,
        }),
      );
    } catch (error) {
      await dispatch(
        addErrorNotification({
          title: 'Ошибка',
          message: 'Превышен максимальный размер файла',
        }),
      );
      await dispatch(updateUtilsState({loading: false}));
    }
  } catch (error) {
    await dispatch(updateUtilsState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: 'Ошибка',
        message: 'Превышен максимальный размер файла',
      }),
    );
  }
};

export const setAuthToken = (value, options = {}) => Cookies.set('Authorization', `Bearer ${value}`, options);

export const getAuthToken = () => Cookies.get('Authorization');
export const deleteAuthToken = () => Cookies.remove('Authorization');

export const defaultState = {
  languages: [],
  loading: false,
};

export const updateUtilsState = createAction('UPDATE_UTILS_STATE');

export const reducer = handleActions(
  {
    [updateUtilsState]: reducerMethods.update,
  },
  defaultState,
);
