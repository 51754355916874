import 'babel-polyfill';
import * as OfflinePluginRuntime from 'offline-plugin/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import {store, history} from './modules/store';
import materialUiTheme from './theme/config';

import {GlobalStyle} from './theme/global';
import {Notification} from './components/common/notification';

import 'moment/locale/ru';
import MyApp from './MyApp';

import {Router} from 'react-router-dom';
import ConnectedRouter from './components/connected-router';

moment.locale('ru');

OfflinePluginRuntime.install();

ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils} locale="ru">
      <MuiThemeProvider theme={materialUiTheme}>
        <Router history={history}>
          <ConnectedRouter>
            <MyApp />
          </ConnectedRouter>
          <GlobalStyle />
          <Notification />
        </Router>
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById('app'),
);
