import React, {lazy, Suspense} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {routes} from '../constants/routes';
import {LinearProgress} from '@material-ui/core';

const Main = lazy(() => import('../pages/main'));
const Data = lazy(() => import('../pages/data'));
const Staff = lazy(() => import('../pages/staff'));
const Materials = lazy(() => import('../pages/materials'));
const Schedule = lazy(() => import('../pages/schedule'));
const Tests = lazy(() => import('../pages/tests'));
const NewsPage = lazy(() => import('../pages/news'));
const Interviewer = lazy(() => import('../pages/interviewer'));
const InterviewPage = lazy(() => import('../pages/interview'));
const Teams = lazy(() => import('../pages/teams'));
const Attendance = lazy(() => import('../pages/attendance'));

export const Routes = () => (
    <Suspense fallback={<LinearProgress color="secondary" />}>
        <Switch>
            <Route path={routes.main} exact component={Main} />
            <Route path={routes.data} exact component={Data} />
            <Route path={routes.all_staff} exact component={Staff} />
            <Route path={routes.all_staff_item} component={Staff} />
            <Route path={routes.volunteers} exact component={Staff} />
            <Route path={routes.temporary_staff} exact component={Staff} />
            <Route path={routes.materials} exact component={Materials} />
            <Route path={routes.news} exact component={NewsPage} />
            <Route path={routes.tests} exact component={Tests} />
            <Route path={routes.schedule} exact component={Schedule} />
            <Route path={routes.singleTest} component={Tests} />
            <Route path={routes.interviewer} component={Interviewer} />
            <Route path={routes.interview} component={InterviewPage} />
            <Route path={routes.teams} component={Teams} />
            <Route path={routes.attendance} component={Attendance} />
            <Redirect path="/*" to={routes.data} />
        </Switch>
    </Suspense>
);
