import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Notifications from 'react-notification-system-redux';

const style = {
    NotificationItem: {
        DefaultStyle: {
            margin: '10px 5px 2px 1px',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '22px'
        }
    }
};

class NotificationBase extends Component {
    render() {
        const {notifications} = this.props;
        return <Notifications notifications={notifications} style={style} />;
    }
}

NotificationBase.propTypes = {
    notifications: PropTypes.array.isRequired
};

const mapStateToProps = ({notifications}) => ({
    notifications
});

export const Notification = connect(mapStateToProps)(NotificationBase);
