import axios from 'axios';
import {createAction, handleActions} from 'redux-actions';
import get from 'lodash/get';
import {reducerMethods} from './redux';
import {handleError} from '../lib/lib/handle-error';
import {addErrorNotification, addSuccessNotification} from './notifications';
import {routes} from '../constants/routes';
import {history} from './store';
import {reset} from 'redux-form';

// eslint-disable-next-line require-await
const calcListStats = async ({total, getState}) => {
  if (total === 0) {
    return {
      totalLength: 0,
      pagesCount: 0,
      pageRanges: [],
      currentPage: 0,
      currentRange: '0 - 0',
    };
  }

  const {
    staff: {pageLength},
  } = getState();

  let pagesCount = (total - (total % pageLength)) / pageLength;
  const remainder = total - pagesCount * pageLength;
  const pageRanges = [];

  if (remainder > 0) {
    pagesCount += 1;
  }

  for (let index = 0; index < pagesCount; index += 1) {
    const rangeMin = index * pageLength + 1;
    let rangeMax = 0;

    if (index === pagesCount - 1 && remainder > 0) {
      rangeMax = index * pageLength + remainder;
    } else {
      rangeMax = index * pageLength + pageLength;
    }

    pageRanges.push(`${rangeMin} - ${rangeMax}`);
  }

  return {
    pagesCount,
    pageRanges,
    totalLength: total,
    currentPage: 1,
    currentRange: pageRanges[0],
  };
};

export const getEmployeeList = ({page: newPage, sort: newSort, sortModel, isFilterReset}) => async (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: {
        tokenInfo: {access_token},
      },
      staff: {totalLength, pageLength, pageRanges, sort: oldSort, page: oldPage, filterValues, searchString, savedPage},
    } = getState();

    if (savedPage) {
      newPage = savedPage;
    }

    const newState = {
      loading: true,
      groupSelected: [],
    };

    if (newPage && newPage !== oldPage) {
      newState.currentPage = newPage;
      newState.currentRange = pageRanges[newPage - 1];
    }

    if (newSort && newSort !== oldSort) {
      newState.sort = newSort;
    }

    if (sortModel) {
      newState.sortModel = sortModel;
    }

    if (isFilterReset) {
      newState.filterValues = {};
    }

    await dispatch(updateStaffState(newState));

    const fields = [
      'id,lastName,firstName,patronymic,birthday,',
      'phoneNumber,email,application.rejectReason,',
      'application.manager,languages,application.status,englishLevel',
    ].join('');

    const params = {
      sort: newSort || oldSort,
      page: newPage || oldPage,
      limit: pageLength,
      fields,
    };

    const {pathname} = window.location;

    if (pathname === routes.volunteers) {
      params['application.role.id'] = 3;
    } else if (pathname === routes.temporary_staff) {
      params['application.role.id'] = 4;
    }

    if (!isFilterReset) {
      Object.keys(filterValues).forEach(key => {
        if (!filterValues[key]) {
          return;
        }

        params[key] = filterValues[key];
      });
    }

    if (searchString !== '') {
      params.q = searchString;
    }

    try {
      try {
        const options = {
          method: 'GET',
          url: '/api/manager-employee/list',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
          },
          params,
        };

        const {
          data: {
            data,
            summary: {total},
          },
        } = await axios(options);

        let stats = {};
        if (total !== totalLength) {
          stats = await calcListStats({total, getState});
        }

        await dispatch(
          updateStaffState({
            staffList: data,
            loading: false,
            ...stats,
          }),
        );
      } catch (error) {
        handleError(error);
      }
    } catch (error) {
      dispatch(updateStaffState({loading: false}));
      const statusCode = get(error, ['errorData', 'statusCode']);
      if (statusCode === 403) {
        dispatch(
          addErrorNotification({
            title: 'Ошибка',
            message: 'Нет прав доступа',
          }),
        );
        history.push('/');
        return false;
      }
      if (statusCode === 401) {
        history.push('/');
        return false;
      }
      await dispatch(
        addErrorNotification({
          title: get(error, ['errorData', 'statusCode']),
          message: get(error, ['errorData', 'message']),
        }),
      );
    }
  } catch {
    console.warn('.');
  }
};

export const getFilters = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'GET',
        url: '/api/manager-employee/list/filters',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      };

      const {
        data: {filters},
      } = await axios(options);
      filters.sort((a, b) => a.order - b.order);
      await dispatch(updateStaffState({filters}));
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    dispatch(updateStaffState({loading: false}));
    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      return false;
    }
    if (statusCode === 401) {
      history.push('/');
      return false;
    }
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const getEmployeeItem = id => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'GET',
        url: `/api/manager-employee/volunteer/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      };

      await dispatch(updateStaffState({loading: true}));

      const {data} = await axios(options);
      const menu = await dispatch(getItemDistributionMenu());

      await dispatch(
        updateStaffState({
          loading: false,
          staffItem: data,
          distributionMenu: menu,
        }),
      );
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    await dispatch(updateStaffState({loading: false}));

    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      history.push('/');
      return false;
    }
    if (statusCode === 401) {
      history.push('/');
      return false;
    }
    if (statusCode === 404) {
      history.push('/data/all_staff');
      await dispatch(
        addErrorNotification({
          title: 'Ошибка',
          message: `Анкеты № ${id} не существует`,
        }),
      );
      return false;
    }
    if (statusCode === 400) {
      history.push('/data/all_staff');
      await dispatch(
        addErrorNotification({
          title: 'Ошибка',
          message: 'Идентификатор анкеты не может быть строкой',
        }),
      );
      return false;
    }

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const updateEmployeeItem = ({id, data}) => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'PUT',
        url: `/api/manager-employee/volunteer/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        data,
      };

      await dispatch(updateStaffState({loading: true}));
      const {data: staffItem} = await axios(options);

      await dispatch(
        updateStaffState({
          staffItem,
          loading: false,
        }),
      );

      await dispatch(
        addSuccessNotification({
          title: 'Успешно',
          message: 'Анкета изменена',
        }),
      );
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    await dispatch(updateStaffState({loading: false}));

    const message = get(error, ['errorData', 'message']);
    if (message === 'Leave comment before set INTERVIEW_PASSED status') {
      await dispatch(
        addErrorNotification({
          title: 'Ошибка',
          message: 'Изменить статус анкеты не удалось. Нет результатов интервью',
        }),
      );
      return false;
    }

    if (message === 'Team volunteers limit exceeded') {
      await dispatch(
        addErrorNotification({
          title: 'Ошибка',
          message: 'В команде нет свободного слота',
        }),
      );
      return false;
    }

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const sendRegisterNewStaff = values => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'POST',
        url: '/api/manager-employee/register',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        data: values,
      };

      await dispatch(updateStaffState({loading: true}));
      const {data} = await axios(options);
      await dispatch(updateStaffState({loading: false}));
      dispatch(reset('addStaff'));

      await dispatch(
        addSuccessNotification({
          title: 'Успешно',
          message: 'Регистрационные данные отправлены',
        }),
      );

      return data;
    } catch (error) {
      console.error(error);
      handleError(error);
    }
  } catch (error) {
    await dispatch(updateStaffState({loading: false}));

    const code = get(error, ['errorData', 'statusCode']);

    if (code === 500) {
      await dispatch(
        addErrorNotification({
          title: 'Ошибка',
          message: 'Этот email уже используется',
        }),
      );
      return false;
    }

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const createOneTeam = data => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'POST',
        url: '/api/team',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        data,
      };

      await dispatch(updateStaffState({loading: true}));
      const {data: result} = await axios(options);

      return result;
    } catch (error) {
      console.error(error);
      handleError(error);
    }
  } catch (error) {
    await dispatch(updateStaffState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const addEmployeeItemComment = ({id, data}) => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'POST',
        url: `/api/manager-employee/volunteer/${id}/comment`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        data,
      };

      await dispatch(updateStaffState({loading: true}));

      const {data: staffItem} = await axios(options);

      await dispatch(
        addSuccessNotification({
          title: 'Успех',
          message: 'Комментарий успешно добавлен',
        }),
      );

      await dispatch(
        updateStaffState({
          staffItem,
          loading: false,
        }),
      );
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    await dispatch(updateStaffState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const sendStaffDataGridGroupChange = data => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'PUT',
        url: '/api/manager-employee/volunteer/bulk',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        data,
      };

      await dispatch(updateStaffState({loading: true}));

      await axios(options);

      await dispatch(
        updateStaffState({
          // StaffItem,
          loading: false,
        }),
      );
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    await dispatch(updateStaffState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const getItemDistributionMenu = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'GET',
        url: '/api/manager-employee/menu',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      };

      const {data} = await axios(options);
      await dispatch(updateStaffState({distributionMenu: data}));
      return data;
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }

  return [];
};

export const getVolounteerRoles = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'GET',
        url: '/api/manager-employee/roles',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      };

      const {data} = await axios(options);
      await dispatch(updateStaffState({roles: data}));
      return data;
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      history.push('/');
      return [];
    }
    if (statusCode === 401) {
      history.push('/');
      return [];
    }
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }

  return [];
};

export const getManagersList = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'GET',
        url: '/api/manager-employee/list?application.role.id=1',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      };

      await dispatch(updateStaffState({loading: true}));

      const {
        data: {data},
      } = await axios(options);

      await dispatch(updateStaffState({loading: false}));

      return data;
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      history.push('/');
      return false;
    }
    if (statusCode === 401) {
      history.push('/');
      return false;
    }
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const updateStaffEmployee = ({id, ...body}) => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'PUT',
        url: `/api/manager-employee/employee/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        data: body,
      };

      await dispatch(updateStaffState({loading: true}));

      const {data} = await axios(options);

      await dispatch(
        updateStaffState({
          loading: false,
          staffItem: data,
        }),
      );

      await dispatch(setStaffEdit(false));

      await dispatch(
        addSuccessNotification({
          title: 'Успешно',
          message: 'Анкета изменена',
        }),
      );

      return data;
    } catch (error) {
      await dispatch(updateStaffState({loading: false}));
      handleError(error);
    }
  } catch (error) {
    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      history.push('/');
      return false;
    }
    if (statusCode === 401) {
      history.push('/');
      return false;
    }
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const getStatusList = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'GET',
        url: '/api/application-status',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      };

      await dispatch(updateStaffState({loading: true}));

      const {data} = await axios(options);

      await dispatch(updateStaffState({loading: false}));

      return data;
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      history.push('/');
      return false;
    }
    if (statusCode === 401) {
      history.push('/');
      return false;
    }
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const getRejectList = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'GET',
        url: '/api/application/reject-reason?type=1',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      };

      await dispatch(updateStaffState({loading: true}));

      const {data} = await axios(options);

      await dispatch(updateStaffState({rejectReasonList: [...data], loading: false}));
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      history.push('/');
      return false;
    }
    if (statusCode === 401) {
      history.push('/');
      return false;
    }
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const getXlsEmployeeList = ({page: newPage, sort: newSort}) => async (dispatch, getState) => {
  try {
    const {
      auth: {
        tokenInfo: {access_token},
      },
      staff: {totalLength, sort: oldSort, page: oldPage, groupSelected},
    } = getState();

    const fields = [
      'id',
      'lastName',
      'firstName',
      'patronymic',
      'birthday',
      'citizenship.i18n.ru',
      'registrationAddrCity.i18n.ru',
      'phoneNumber',
      'email',
      'englishLevel.i18n.ru',
      'application.status.description',
      'resumeFile',
      'hasResume',
      'education',
      'application.desiredFunction.function.i18n.ru',
      'passport',
      'ITN',
      'INILA',
      'application.isLeader',
      'application.team.description',
      'application.area.description',
      'clothesSize.value',
      'photo.url',
      'application.manager.lastname',
      'application.manager.firstname',
      'application.manager.patronymic',
    ].join(',');

    const show_fields = [
      'id',
      'lastName',
      'firstName',
      'patronymic',
      'birthday',
      'citizenship.i18n.ru',
      'registrationAddrCity.i18n.ru',
      'phoneNumber',
      'email',
      'englishLevel.i18n.ru',
      'application.status.description',
      'hasResume',
      'education.0.summary',
      'education.1.summary',
      'education.2.summary',
      'application.desiredFunction.0.function.i18n.ru',
      'application.desiredFunction.1.function.i18n.ru',
      'application.desiredFunction.2.function.i18n.ru',
      'passport',
      'ITN',
      'INILA',
      'application.isLeader',
      'application.team.description',
      'application.area.description',
      'clothesSize.value',
      'photo.url',
      'application.manager.lastname',
      'application.manager.firstname',
      'application.manager.patronymic',
    ].join(',');

    const headers = [
      'ID',
      'Фамилия',
      'Имя',
      'Отчество',
      'Дата+рождения',
      'Гражданство',
      'Адрес+регистрации',
      'Номер+телефона',
      'Электронная+почта',
      'Уровень+владения+русским',
      'Статус',
      'Резюме',
      'Образование+1',
      'Образование+2',
      'Образование+3',
      'Желаемая+Функция+1',
      'Желаемая+Функция+2',
      'Желаемая+Функция+3',
      'Номер_Паспорта',
      'ИНН',
      'СНИЛС',
      'Лидер_команды',
      'Команда',
      'Направление',
      'Размер_Одежды',
      'Фото',
      'Фамилия+Менеджера',
      'Имя+Менеджера',
      'Отчество+Менеджера',
    ].join(',');

    const params = {
      sort: newSort || oldSort,
      page: newPage || oldPage,
      limit: totalLength,
      show_fields: process.env.REACT_APP_XLS_SHOW_FIELDS || show_fields,
      id: groupSelected.join(','),
      fields: process.env.REACT_APP_XLS_FIELDS || fields,
      headers: process.env.REACT_APP_XLS_HEADERS || headers,
    };

    try {
      const options = {
        method: 'GET',
        url: '/api/manager-employee/list/xls',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
          Authorization: `Bearer ${access_token}`,
        },
        params,
        responseType: 'blob',
      };

      const {data} = await axios(options);

      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      });

      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `employee-list-(${new Date().toLocaleString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })})`;
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      dispatch(
        addErrorNotification({
          title: 'Ошибка',
          message: 'Нет прав доступа',
        }),
      );
      history.push('/');
      return false;
    }
    if (statusCode === 401) {
      history.push('/');
      return false;
    }
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const setStaffEdit = isEdit => dispatch => {
  dispatch(updateStaffState({isEdit}));
};

const staffListDefault = {
  staffList: [],
  groupSelected: [],
  staffItem: null,
  loading: true,
  sort: '-employee.id',
  sortModel: {
    field: 'id',
    sort: 'desc',
  },
  totalLength: 0,
  pagesCount: 0,
  pageLength: 25,
  pageRanges: [],
  currentPage: 0,
  savedPage: 0,
  currentRange: '0 - 0',
  filterValues: {},
  searchString: '',
  distributionMenu: [],
  roles: [],
  rejectReasonList: [],
  isEdit: false,
};

export const resetStaffList = () => dispatch => dispatch(updateStaffState(staffListDefault));

export const defaultState = {
  ...staffListDefault,
  filters: [],
  filterItems: [],
  filterInnerValues: {},
};

export const updateStaffState = createAction('UPDATE_STAFF_STATE');

export const reducer = handleActions(
  {
    [updateStaffState]: reducerMethods.update,
  },
  defaultState,
);
