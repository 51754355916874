import {createTheme} from '@material-ui/core/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#32706A',
      light: '#F6F9FD',
    },
    secondary: {
      main: '#C6873E',
    },
  },
  typography: {
    subtitle2: {
      textView: 'bold',
    },
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        marginRight: '0 !important',
      },
    },
    MuiInputLabel: {
      formControl: {
        left: 'auto',
      },
    },
    MuiDialog: {
      paperWidthSm: {
        minWidth: '400px',
      },
    },
    MuiButton: {
      label: {
        gap: 6,
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
});
