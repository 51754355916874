import {handleActions, createAction} from 'redux-actions';
import {success, warning, info, error} from 'react-notification-system-redux';
import {reducerMethods} from './redux';
import config from '../lib/lib/config';

export const replaceNotificationsCustom = createAction('REPLACE_NOTIFICATION_CUSTOM');
export const addNotificationsCustom = createAction('ADD_NOTIFICATIONS_CUSTOM');

export const addSuccessNotification = ({message, title = 'success', customConfig}) =>
  success({
    ...config.notification,
    title,
    message,
    autoDismiss: 2,
    ...customConfig,
  });

export const addErrorNotification = ({message, title = 'error', customConfig}) =>
  error({
    ...config.notification,
    title,
    message,
    autoDismiss: 2,
    ...customConfig,
  });

export const addWarningNotification = ({message, title = 'warning', customConfig}) =>
  warning({
    ...config.notification,
    title,
    message,
    autoDismiss: 2,
    ...customConfig,
  });

export const addInfoNotification = ({message, title = 'info', customConfig}) =>
  info({
    ...config.notification,
    title,
    message,
    autoDismiss: 2,
    ...customConfig,
  });

export const defaultState = [];

export const reducer = handleActions(
  {
    [replaceNotificationsCustom]: reducerMethods.identity,
    [addNotificationsCustom]: reducerMethods.add,
  },
  defaultState,
);
