import React, {Fragment, useEffect, memo} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

const ConnectedRouter = ({children}) => {
    const history = useHistory();

    const path = useSelector(state => state.router.path);

    useEffect(() => {
        history.push(path);
    }, [history, path]);

    return <Fragment>{children}</Fragment>;
};

export default memo(ConnectedRouter);
