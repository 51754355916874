import {CircularProgress, makeStyles} from '@material-ui/core';
import React from 'react';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(() => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    width: '100vw',
    height: '100vh',
  },
  loading: {},
}));

const Loading = () => {
  const classes = useStyles();

  const {appInProgress} = useSelector(state => state.global);

  if (!appInProgress) {
    return null;
  }

  return (
    <div className={classes.container}>
      <CircularProgress color="secondary" size="60px" />
    </div>
  );
};

export default React.memo(Loading);
