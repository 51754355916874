import axios from 'axios';
import {createAction, handleActions} from 'redux-actions';
import get from 'lodash/get';
import moment from 'moment';
import {reducerMethods} from './redux';
import {handleError} from '../lib/lib/handle-error';
import { addErrorNotification } from './notifications';

const localScheduleKey = 'schedule';

const saveLocalSchedule = data => {
  const jsonData = JSON.stringify(data);
  localStorage.setItem(localScheduleKey, jsonData);
};

export const getInterviewers = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    const options = {
      method: 'GET',
      url: '/api/manager-employee/list?application.role.id=5',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    };
    await dispatch(updateScheduleState({loading: true}));

    const {data} = await axios(options);

    const newData = data?.data.map(interviewer => {
      return {
        ...interviewer,
        workTime: 600,
        schedule: [],
      };
    });

    await dispatch(updateScheduleState({interviewersList: newData, loading: false}));
  } catch (error) {
    handleError(error);
  }
};

export const addInterviewerScheduleDay = date => (dispatch, getState) => {
  const {
    schedule: {interviewersList},
  } = getState();

  const currentDay = new Date(date).setHours(9, 0, 0, 0);
  const currentDayTime = new Date(currentDay).getTime();

  const getTimeBlocks = interval => {
    const totalTimeBlocks = 600 / interval;
    const timeBlocks = [];
    for (let i = 0; i <= totalTimeBlocks; i += 1) {
      const currentTime = currentDayTime + i * interval * 60000;
      timeBlocks.push({
        id: currentTime,
        startTime: new Date(currentTime).toLocaleString('ru-RU', {
          hour: 'numeric',
          minute: 'numeric',
        }),
        endTime: new Date(currentTime + interval * 60000).toLocaleString('ru-RU', {hour: 'numeric', minute: 'numeric'}),
        status: '',
        isFree: false,
        isWork: false,
        busy: {},
      });
    }
    return timeBlocks;
  };

  const data = interviewersList.map(interviewer => {
    if (interviewer.schedule.find(day => day.id === currentDayTime)) {
      return interviewer;
    }
    interviewer.schedule.push({
      id: currentDayTime,
      inWork: false,
      timeBlocks: getTimeBlocks(60),
    });
    return interviewer;
  });

  saveLocalSchedule(data);

  dispatch(updateScheduleState({interviewersList: [...data], loading: false}));
};

export const changeWorkStatus = (id, selectedDay, inWork) => (dispatch, getState) => {
  const {
    schedule: {interviewersList},
  } = getState();

  const data = interviewersList.map(interviewer => {
    if (interviewer.id === id) {
      interviewer.schedule.forEach(day => {
        if (day.id === selectedDay) {
          day.inWork = inWork;
          day.timeBlocks.forEach(el => {
            el.isFree = !!inWork;
            if (!inWork) {
              el.isWork = false;
            }
          });
        }
      });
      return interviewer;
    }
    return interviewer;
  });

  saveLocalSchedule(data);

  dispatch(updateScheduleState({interviewersList: [...data], loading: false}));
};

export const changeFreeStatus = (id, selectedDay, selectedTime, free) => (dispatch, getState) => {
  const {
    schedule: {interviewersList},
  } = getState();

  const data = interviewersList.map(interviewer => {
    if (interviewer.id === id) {
      interviewer.schedule.forEach(day => {
        if (day.id === selectedDay) {
          day.timeBlocks.forEach(block => {
            if (block.id === selectedTime) {
              block.isFree = free;
            }
          });
        }
      });
      return interviewer;
    }
    return interviewer;
  });

  saveLocalSchedule(data);

  dispatch(updateScheduleState({interviewersList: [...data], loading: false}));
};

export const changeWorkTime = (id, selectedDay, selectedTime, work) => (
  dispatch,

  getState,
) => {
  const {
    schedule: {interviewersList},
  } = getState();

  const data = interviewersList.map(interviewer => {
    if (interviewer.id === id) {
      interviewer.schedule.forEach(day => {
        if (day.id === selectedDay) {
          day.timeBlocks.forEach(block => {
            if (block.id === selectedTime) {
              block.isWork = work;
              if (work === true) {
                block.isFree = false;
              }
            }
          });
        }
      });
      return interviewer;
    }
    return interviewer;
  });

  saveLocalSchedule(data);

  dispatch(updateScheduleState({interviewersList: [...data], loading: false}));
};

export const addInterviewTime = (id, selectedDay, selectedTime, employee) => (dispatch, getState) => {
  const {
    schedule: {interviewersList},
  } = getState();

  const data = interviewersList.map(interviewer => {
    if (interviewer.id === id) {
      interviewer.schedule.forEach(day => {
        if (day.id === selectedDay) {
          day.timeBlocks.forEach(block => {
            if (block.id === selectedTime) {
              block.busy = employee;
            }
          });
        }
      });
      return interviewer;
    }
    return interviewer;
  });

  saveLocalSchedule(data);

  dispatch(updateScheduleState({interviewersList: [...data], loading: false}));
};

export const getReadyStaff = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  const fields = ['id,lastName,firstName,patronymic,birthday,', 'citizenship,email,photo,', 'application.manager,languages,application.status'].join(
    '',
  );

  const params = {
    sort: '',
    page: 1,
    limit: 999999,
    fields,
    'application.status.id': 7,
  };

  try {
    const options = {
      method: 'GET',
      url: '/api/manager-employee/list',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      params,
    };

    const {
      data: {
        data,
        // summary: {total}
      },
    } = await axios(options);

    await dispatch(
      updateScheduleState({
        readyStaff: data,
      }),
    );
  } catch (error) {
    handleError(error);
  }
};

export const getFilteredVolunteers = (filters) => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  const fields = ['id,lastName,firstName,patronymic,birthday,', 'citizenship,email,photo,', 'application.manager,languages,application.status'].join(
    '',
  );

  const params = {
    sort: '',
    page: 1,
    limit: 999999,
    fields,
    ...filters,
  };

  try {
    const options = {
      method: 'GET',
      url: '/api/manager-employee/list',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      params,
    };

    const {
      data: {
        data,
        // summary: {total}
      },
    } = await axios(options);

    await dispatch(
      updateScheduleState({
        filteredVolunteers: data,
      }),
    );
  } catch (error) {
    handleError(error);
  }
};

export const getAllVolunteers = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  const fields = ['id,lastName,firstName,patronymic,birthday,', 'citizenship,email,photo,', 'application.manager,languages,application.status,application.id'].join(
    '',
  );

  const params = {
    sort: '',
    page: 1,
    limit: 999999,
    fields,
  };

  try {
    const options = {
      method: 'GET',
      url: '/api/manager-employee/list',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      params,
    };

    const {
      data: {
        data,
        // summary: {total}
      },
    } = await axios(options);

    await dispatch(
      updateScheduleState({
        allVolunteers: data,
      }),
    );
  } catch (error) {
    handleError(error);
  }
};

export const getInterviewersWorkTime = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  const startDate = '2021-07-01T21:23:58.999Z';
  const endDate = '2021-07-16T21:23:58.999Z';

  try {
    const options = {
      method: 'GET',
      url: '/api/interview/interviewer-work-time',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      params: {
        startDate,
        endDate,
      },
    };

    const {data} = await axios(options);

    console.warn(data, 'data');
  } catch (error) {
    console.warn(error, 'Ошибка');
    handleError(error);
  }
};

export const createInterviewerWorkTime = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  const today = Date.now();

  const startDate = new Date(today).toISOString();
  const endDate = new Date(today + 24 * 3600 * 1000).toISOString();

  try {
    const options = {
      method: 'POST',
      url: '/api/interview/interviewer-work-time',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      data: {
        startDate,
        endDate,
        employee: [
          {
            id: 67,
          },
        ],
      },
    };

    const {data} = await axios(options);

    console.warn(data, 'data');
  } catch (error) {
    console.warn(error, 'Ошибка');
    handleError(error);
  }
};

export const getShiftsListXML = (params) => async dispatch => {
  const {startDate, endDate, ...rest} = params;
  try {
    try {
      const options = {
        method: 'GET',
        url: `/api/shift/download/xls`,
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
        },
        params: {
          // startDate: moment(startDate).format('YYYY-MM-DD'),
          // endDate: moment(endDate).format('YYYY-MM-DD'),
        },
        responseType: 'blob',
      };
      const {data} = await axios(options);

      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      });

      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `shift-list-(${new Date().toLocaleString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })})`;
      document.body.appendChild(a);
      a.click();

    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      return false;
    }
    if (statusCode === 401) {
      return false;
    }
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const defaultState = {
  interviewersList: [],
  readyStaff: [],
  filteredVolunteers: [],
  allVolunteers: [],
  loading: false,
};

export const resetScheduleState = () => dispatch => dispatch(updateScheduleState(defaultState));

export const updateScheduleState = createAction('UPDATE_SCHEDULE_STATE');

export const reducer = handleActions(
  {
    [updateScheduleState]: reducerMethods.update,
  },
  defaultState,
);
