import axios from 'axios';
import {createAction, handleActions} from 'redux-actions';
import {formValueSelector} from 'redux-form';
import get from 'lodash/get';
import {reducerMethods} from './redux';
import {routes} from '../constants/routes';

import {authForm} from '../constants/forms';
import {replaceAppLoading} from './global';
import {handleError} from '../lib/lib/handle-error';
import {addErrorNotification} from './notifications';
import {getAuthToken, setAuthToken} from './utils';
import {history} from './store';

export const resumeSession = () => dispatch => {
    const authToken = getAuthToken();
    if (authToken) {
        const options = {
            method: 'POST',
            url: '/api/manager-auth/login/token',
            headers: {
                'Content-Type': 'application/json',
                Authorization: authToken
            }
        };
        dispatch(replaceAppLoading(true));

        axios(options)
            .then(({data}) => {
                dispatch(replaceTokenInfo(data.token));
                dispatch(replaceUserInfo(data.data));

                const {access_token, expiration_access_time} = data.token;
                setAuthToken(access_token, {
                    expired: expiration_access_time
                });
                dispatch(replaceAppLoading(false));
                return true;
            })
            .catch(() => {
                const {pathname} = history?.location;
                if (!pathname.includes('/data/interviewer')) {
                    history.push('/');
                }
                dispatch(replaceAppLoading(false));
                /*
                 * Dispatch(addErrorNotification({
                 *   title: 'Сессия закончена',
                 *   message: 'Необходима авторизация',
                 * }));
                 */
                return false;
            });
    }
};

export const loginUser = history => async (dispatch, getState) => {
    const formSelector = formValueSelector(authForm.name);
    const values = formSelector(getState(), ...authForm.fields);
    await dispatch(replaceAppLoading(true));

    try {
        try {
            const url = '/api/manager-auth/login';
            const {data} = await axios.post(url, values);

            await dispatch(replaceTokenInfo(data.token));
            await dispatch(replaceUserInfo(data.data));
            // SetTimeout(() => dispatch(replaceAppLoading(false)), 2000);
            await dispatch(replaceAppLoading(false));

            const {access_token, expiration_access_time} = data.token;
            setAuthToken(access_token, {
                expired: expiration_access_time
            });
            history.replace(routes.all_staff);
        } catch (error) {
            console.error('error');
            handleError(error);
        }
    } catch (error) {
        await dispatch(replaceAppLoading(false));
        await dispatch(
            addErrorNotification({
                title: get(error, ['errorData', 'statusCode']),
                message: get(error, ['errorData', 'message'])
            })
        );
    }
};

export const defaultState = {
    tokenInfo: null,
    userInfo: null,
    userType: null
};

export const replaceTokenInfo = createAction('REPLACE_TOKEN_INFO');
export const replaceUserInfo = createAction('REPLACE_USER_INFO');

export const reducer = handleActions(
    {
        [replaceTokenInfo]: reducerMethods.replace('tokenInfo'),
        [replaceUserInfo]: reducerMethods.replace('userInfo')
    },
    defaultState
);
