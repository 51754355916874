import axios from 'axios';
import {createAction, handleActions} from 'redux-actions';
import get from 'lodash/get';
import {handleError} from '../lib/lib/handle-error';
import {addErrorNotification} from './notifications';
import {reducerMethods} from './redux';
import {history} from './store';

export const getLogsList = () => async (dispatch, getState) => {
    const {
        auth: {
            tokenInfo: {access_token}
        }
    } = getState();

    try {
        try {
            const options = {
                method: 'GET',
                url: '/api/Employee-logs/all',
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            };

            const {data} = await axios(options);
            await dispatch(updateLogsState({logsList: data}));
        } catch (error) {
            handleError(error);
        }
    } catch (error) {
        const statusCode = get(error, ['errorData', 'statusCode']);
        if (statusCode === 403) {
            history.push('/');
            await dispatch(
                addErrorNotification({
                    title: 'Ошибка',
                    message: 'Нет прав доступа'
                })
            );
            return false;
        }
        if (statusCode === 401) {
            history.push('/');
            return false;
        }
        await dispatch(
            addErrorNotification({
                title: get(error, ['errorData', 'statusCode']),
                message: get(error, ['errorData', 'message'])
            })
        );
    }
};

export const getSelectedEmployeeLogs = id => async (dispatch, getState) => {
    const {
        auth: {
            tokenInfo: {access_token}
        }
    } = getState();

    try {
        try {
            const options = {
                method: 'GET',
                url: '/api/Employee-logs/id',
                headers: {
                    Authorization: `Bearer ${access_token}`
                },
                params: {
                    id
                }
            };

            const {data} = await axios(options);
            await dispatch(updateLogsState({selectedLogsList: data}));
            return data;
        } catch (error) {
            handleError(error);
        }
    } catch (error) {
        const statusCode = get(error, ['errorData', 'statusCode']);
        if (statusCode === 403) {
            history.push('/');
            await dispatch(
                addErrorNotification({
                    title: 'Ошибка',
                    message: 'Нет прав доступа'
                })
            );
            return false;
        }
        if (statusCode === 401) {
            history.push('/');
            return false;
        }
        await dispatch(
            addErrorNotification({
                title: get(error, ['errorData', 'statusCode']),
                message: get(error, ['errorData', 'message'])
            })
        );
    }
};

export const defaultState = {
    logsList: [],
    selectedLogsList: []
};

export const updateLogsState = createAction('UPDATE_LOGS_STATE');

export const reducer = handleActions(
    {
        [updateLogsState]: reducerMethods.update
    },
    defaultState
);
