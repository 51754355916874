const config = {
  acceptedImgFormats: process.env.ACCEPTED_IMAGE_FORMATS || 'image/*',
  acceptedFileFormats: process.env.ACCEPTED_FILE_FORMATS || '.doc,.pdf,.docx,.png,.txt',
  port: process.env.PORT || '80',
  remoteHost: process.env.REMOTE_HOST || 'https://manager.ossp.spb.ru/api',
  imagerHost: process.env.IMAGER_HOST || 'https://manager.ossp.spb.ru/api/static',

  notification: {
    position: 'tr',
    autoDismiss: 3,
    action: {
      label: 'Ок',
    },
  },

  hasTechnicalWork: process.env.HAS_TECHNICAL_WORK || 'false',
};

module.exports = config;
