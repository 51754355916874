import axios from 'axios';
import {createAction, handleActions} from 'redux-actions';
import get from 'lodash/get';
import {reducerMethods} from './redux';
import {handleError} from '../lib/lib/handle-error';
import {addErrorNotification, addSuccessNotification} from './notifications';
import {history} from '../modules/store';
import {reset} from 'redux-form';

export const getTests = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'GET',
        url: '/api/training/manager/test',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      };
      await dispatch(updateTestState({loading: true}));

      const {data} = await axios(options);

      const sortQuestions = data.map(test => {
        return {
          ...test,
          questions: test.questions
            .sort((a, b) => a.order - b.order)
            .map(question => {
              return {
                ...question,
                answers: question.answers.sort((a, b) => a.order - b.order),
              };
            }),
        };
      });

      await dispatch(updateTestState({testList: sortQuestions, loading: false}));
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      history.push('/');
      return false;
    }
    if (statusCode === 401) {
      history.push('/');
      return false;
    }
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const getPersonalTests = id => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'GET',
        url: '/api/training/manager/test',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        params: {
          applicationId: id,
        },
      };
      await dispatch(updateTestState({loading: true}));

      const {data} = await axios(options);

      await dispatch(updateTestState({loading: false, personalTests: data}));
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      history.push('/');
      return false;
    }
    if (statusCode === 401) {
      history.push('/');
      return false;
    }
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const removePersonalTestResults = (testId, employeeId, applicationId) => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'POST',
        url: `/api/training/manager/employee/${employeeId}/test/${testId}/resetAttempts`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        data: {}
      };
      await dispatch(updateTestState({loading: true}));

      await axios(options);

      await dispatch(getPersonalTests(applicationId));
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      history.push('/');
      return false;
    }
    if (statusCode === 401) {
      history.push('/');
      return false;
    }
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const removePersonalTests = () => dispatch => {
  dispatch(updateTestState({personalTests: []}));
};

export const setSelectTest = id => dispatch => {
  dispatch(updateTestState({selectTest: id}));
  history.push(`/data/tests/${id}`);
};

export const resetSelectTest = () => dispatch => {
  dispatch(updateTestState({selectTest: null}));
  history.push('/data/tests');
};

export const sendNewTest = values => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    const options = {
      method: 'POST',
      url: '/api/training/manager/test',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      data: values,
    };

    await dispatch(updateTestState({loading: true}));

    const {data} = await axios(options);
    await dispatch(setSelectTest(data.id));
    await dispatch(reset('createTest'));

    await dispatch(getTests());

    await dispatch(
      updateTestState({
        loading: false,
      }),
    );
  } catch (error) {
    await dispatch(updateTestState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const addNewQuestion = (values, id) => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    const options = {
      method: 'POST',
      url: `/api/training/manager/test/${id}/question`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      data: values,
    };

    await dispatch(updateTestState({loading: true}));

    await axios(options);

    await dispatch(getTests());

    await dispatch(
      updateTestState({
        loading: false,
      }),
    );
  } catch (error) {
    await dispatch(updateTestState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const editQuestion = (values, test, id) => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    const options = {
      method: 'PUT',
      url: `/api/training/manager/test/${test}/question/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      data: values,
    };

    await dispatch(updateTestState({loading: true}));

    await axios(options);

    await dispatch(
      updateTestState({
        loading: false,
      }),
    );
  } catch (error) {
    await dispatch(updateTestState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const deleteQuestion = (test, id) => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    const options = {
      method: 'DELETE',
      url: `/api/training/manager/test/${test}/question/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    };

    await dispatch(updateTestState({loading: true}));

    await axios(options);

    await dispatch(getTests());

    await dispatch(
      updateTestState({
        loading: false,
      }),
    );
  } catch (error) {
    await dispatch(updateTestState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const deleteTest = id => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    const options = {
      method: 'DELETE',
      url: `/api/training/manager/test/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    };

    await dispatch(updateTestState({loading: true}));

    await axios(options);

    await dispatch(setSelectTest(null));
    await dispatch(getTests());

    await dispatch(
      updateTestState({
        loading: false,
      }),
    );
  } catch (error) {
    await dispatch(updateTestState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const updateSelectTest = (values, id) => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    const options = {
      method: 'PUT',
      url: `/api/training/manager/test/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      data: values,
    };

    await dispatch(updateTestState({loading: true}));

    // eslint-disable-next-line no-unused-vars
    const {data} = await axios(options);

    await dispatch(resetSelectTest());

    await dispatch(getTests());

    await dispatch(
      updateTestState({
        loading: false,
      }),
    );
  } catch (error) {
    await dispatch(updateTestState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const saveSelectTestChange = (values, id) => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    const options = {
      method: 'PUT',
      url: `/api/training/manager/test/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      data: values,
    };

    await dispatch(updateTestState({loading: true}));

    // eslint-disable-next-line no-unused-vars
    const {data} = await axios(options);

    await dispatch(getTests());

    await dispatch(
      updateTestState({
        loading: false,
      }),
    );

    await dispatch(
      addSuccessNotification({
        title: 'Успешно',
        message: 'Тест успешно сохранен',
      }),
    );
  } catch (error) {
    await dispatch(updateTestState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const defaultState = {
  testList: [],
  selectTest: null,
  loading: false,
  personalTests: [],
};

export const resetTestState = () => dispatch => dispatch(updateTestState(defaultState));

export const updateTestState = createAction('UPDATE_TEST_STATE');

export const reducer = handleActions(
  {
    [updateTestState]: reducerMethods.update,
  },
  defaultState,
);
