import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Startup} from './components/start-up';
import {Header} from './layouts/Header';
import {resumeSession} from './modules/auth';
import {getGeneralMenuItems, replaceAppLoading} from './modules/global';
import {getFilters} from './modules/staff';
import {getUtilsLanguages} from './modules/utils';
import {Routes} from './router';
import {LicenseInfo} from '@mui/x-data-grid-pro';
import Loading from './components/common/Loading';

const MyApp = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  LicenseInfo.setLicenseKey(
    '757fde15454bfadc5efb2102a11de382T1JERVI6MzQ1MjAsRVhQSVJZPTE2NzEzNjcwOTYwMDAsS0VZVkVSU0lPTj0x',
  );

  useEffect(() => {
    dispatch(resumeSession());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {userInfo} = useSelector(({auth}) => auth);

  const preload = async () => {
    await dispatch(replaceAppLoading(true));
    await dispatch(getFilters());
    await dispatch(getUtilsLanguages());
    await dispatch(replaceAppLoading(false));

    const items = await dispatch(getGeneralMenuItems());

    if (items === null) {
      return;
    }

    /*
     * If (items.length > 0) {
     *   history.replace(items[0].path);
     * } else {
     *   history.replace(routes.data);
     * }
     */
  };

  useEffect(() => {
    if (Boolean(userInfo) !== true) {
      return;
    }

    preload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const path = location?.pathname;

  return (
    <Startup>
      {path !== '/' && <Header />}
      <Routes />
      <Loading />
    </Startup>
  );
};

export default React.memo(MyApp);
