export const routes = {
    main: '/',
    data: '/data',
    all_staff: '/data/all_staff',
    all_staff_item: '/data/all_staff/:id?',
    volunteers: '/data/volunteers',
    temporary_staff: '/data/temporary_staff',
    materials: '/data/materials',
    schedule: '/data/schedule',
    tests: '/data/tests',
    news: '/data/news',
    teams: '/data/teams',
    interview: '/data/interview',
    interviewer: '/data/interviewer/:hash?',
    singleTest: '/data/tests/:id?',
    attendance: '/data/attendance',
};
