import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {LinearProgress} from '@material-ui/core';

import {MainScreen} from '../layouts/main-screen';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    height: 150px;
`;

export const Startup = ({children}) => {
    const {appLoading} = useSelector(({global}) => global);

    if (appLoading) {
        return (
            <MainScreen>
                <Container>
                    <LinearProgress color="secondary" />
                    <LinearProgress />
                </Container>
            </MainScreen>
        );
    }

    return children;
};

Startup.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
