import axios from 'axios';
import {createAction, handleActions} from 'redux-actions';
import {get} from 'lodash';
import {reducerMethods} from './redux';
import {handleError} from '../lib/lib/handle-error';
import {addErrorNotification} from './notifications';

export const getNews = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
    news: {page, lang, status},
    materials: {blockId, areaId, teamId},
  } = getState();

  const params = {
    page,
    limit: 10,
    order: {column: 'id', type: 'DESC'},
  };

  params.block = blockId && blockId !== 'general' ? Number(blockId) : 0;
  params.area = areaId ? Number(areaId) : 0;
  params.team = teamId ? Number(teamId) : 0;
  if (lang) {
    params.lang = lang;
  }
  if (status) {
    params.status = status;
  }

  const options = {
    method: 'GET',
    url: '/api/news/manager/list',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    params,
  };

  try {
    try {
      const {data} = await axios(options);

      return data;
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const getMoreNews = () => async (dispatch, getState) => {
  const {
    news: {page, newsList},
  } = getState();

  const nextPart = await dispatch(getNews());

  await dispatch(
    updateNewsState({
      page: page + 1,
      newsList: [...newsList, ...nextPart],
      hasMore: nextPart.length === 10,
    }),
  );
};

export const createNews = ({data}) => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
    news: {newsList},
    materials: {blockId, areaId, teamId},
  } = getState();

  const dataToSend = {
    ...data,
    block: blockId !== 'general' && blockId ? blockId : 0,
    area: areaId || 0,
    team: teamId || 0,
  };

  try {
    try {
      const options = {
        method: 'POST',
        url: '/api/news',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        data: dataToSend,
      };

      await dispatch(updateNewsState({loading: true}));

      const {data: itemNew} = await axios(options);

      const newData = [itemNew, ...newsList];

      await dispatch(
        updateNewsState({
          newsList: newData,
          loading: false,
        }),
      );

      return itemNew;
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    await dispatch(updateNewsState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const createAndPublishNews = ({data}) => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
    news: {newsList},
    materials: {blockId, areaId, teamId},
  } = getState();

  const dataToSend = {
    ...data,
    block: blockId !== 'general' && blockId ? blockId : 0,
    area: areaId || 0,
    team: teamId || 0,
  };

  try {
    try {
      const options = {
        method: 'POST',
        url: '/api/news',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        data: dataToSend,
      };

      await dispatch(updateNewsState({loading: true}));

      const {data: itemNew} = await axios(options);

      const newData = [itemNew, ...newsList];

      await dispatch(
        updateNewsState({
          newsList: newData,
          loading: false,
        }),
      );

      await dispatch(publishNews(itemNew?.id));
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    await dispatch(updateNewsState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const updateNews = ({data}) => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
    // eslint-disable-next-line no-unused-vars
    news: {newsList},
  } = getState();

  try {
    try {
      const options = {
        method: 'PUT',
        url: '/api/news/edit',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        data,
      };

      await dispatch(updateNewsState({loading: true}));

      // eslint-disable-next-line no-unused-vars
      const {data: itemChanged} = await axios(options);

      const freshList = await dispatch(getNews());

      // const newData = newsList.map(element => {
      //   if (element.id === data.id) {
      //     return itemChanged;
      //   }

      //   return element;
      // });

      await dispatch(
        updateNewsState({
          newsList: freshList,
          loading: false,
        }),
      );
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    await dispatch(updateNewsState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const deleteNews = id => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
    news: {newsList},
  } = getState();

  try {
    try {
      const options = {
        method: 'DELETE',
        url: `/api/news/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      };

      await dispatch(updateNewsState({loading: true}));

      const {data: itemChanged} = await axios(options);

      const newData = newsList.map(element => {
        if (element.id === id) {
          return itemChanged;
        }

        return element;
      });

      await dispatch(
        updateNewsState({
          newsList: newData,
          loading: false,
        }),
      );
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    await dispatch(updateNewsState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const publishNews = id => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
    news: {newsList},
  } = getState();

  try {
    try {
      const options = {
        method: 'PUT',
        url: `/api/news/${id}/publish`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      };

      await dispatch(updateNewsState({loading: true}));

      const {data: itemChanged} = await axios(options);

      const newData = newsList.map(element => {
        if (element.id === id) {
          return itemChanged;
        }

        return element;
      });

      await dispatch(
        updateNewsState({
          newsList: newData,
          loading: false,
        }),
      );
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    await dispatch(updateNewsState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const uploadNewsPhoto = source => async (dispatch, getState) => {
  try {
    const {
      auth: {
        tokenInfo: {access_token},
      },
    } = getState();

    const params = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };

    const url = '/api/news/upload/newsPhoto';

    const file = new Blob([source], {type: source.type});
    const formData = new FormData();

    formData.append('isPublic', true);
    formData.append('file', file);

    try {
      const {data} = await axios.post(url, formData, params);

      return data;
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const changeStatus = newStatus => dispatch => {
  dispatch(
    updateNewsState({
      status: newStatus,
    }),
  );
};

export const changeLang = newLang => dispatch => {
  dispatch(
    updateNewsState({
      lang: newLang,
    }),
  );
};

const newsDefault = {
  newsList: [],
  loading: false,
  page: 0,
  hasMore: true,
  lang: '',
  status: '',
};

export const defaultState = newsDefault;
export const updateNewsState = createAction('UPDATE_NEWS_STATE');

export const resetState = () => dispatch =>
  dispatch(
    updateNewsState({
      newsList: [],
      loading: false,
      page: 0,
      hasMore: true,
    }),
  );

export const reducer = handleActions(
  {
    [updateNewsState]: reducerMethods.update,
  },
  defaultState,
);
