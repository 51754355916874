import axios from 'axios';
import {createAction, handleActions} from 'redux-actions';
import get from 'lodash/get';
import {reducerMethods} from './redux';
import {handleError} from '../lib/lib/handle-error';
import {addErrorNotification} from './notifications';
import {history} from './store';
import {initialize} from 'redux-form';
import moment from 'moment';
import {isNotEmpty} from '../utils/referers';

export const getInterviewers = () => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  try {
    try {
      const options = {
        method: 'GET',
        url: '/api/manager-employee/list?application.role.id=5',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      };

      await dispatch(updateInterviewState({loading: true}));

      const {
        data: {data},
      } = await axios(options);

      await dispatch(updateInterviewState({interviewersList: data, loading: false}));

      return data;
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      history.push('/');
      return false;
    }
    if (statusCode === 401) {
      history.push('/');
      return false;
    }
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const getInterviewResult = (hash, form) => async dispatch => {
  try {
    try {
      const options = {
        method: 'GET',
        url: `/api/interview/interview-result/${hash}`,
        headers: {
          'Content-Type': 'application/json',
        },
      };
      await dispatch(updateInterviewState({loading: true}));

      const {data} = await axios(options);

      await dispatch(updateInterviewState({interviewResult: data, loading: false}));

      const distribution = data.distribution?.map(({id}) => ({area: id}));

      dispatch(
        initialize(form, {
          ...data,
          date: moment(),
          time: moment().add(moment.duration()),
          name: `${data.lastName} ${data.firstName} ${data.patronymic}`,
          englishLevel: data.englishLevel?.id,
          distribution: isNotEmpty(distribution) ? distribution : [{}],
        }),
      );
    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      return false;
    }
    if (statusCode === 401) {
      return false;
    }
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const getInterviewersListXML = (query) => async dispatch => {
  const {startDate} = query;
  try {
    try {
      const options = {
        method: 'GET',
        url: `/api/interview/interview-appointment/xls`,
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8',
        },
        params: {startDate: moment(startDate).format('YYYY-MM-DD')},
        responseType: 'blob',
      };
      const {data} = await axios(options);

      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      });

      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `interviewers-list-(${new Date().toLocaleString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })})`;
      document.body.appendChild(a);
      a.click();

    } catch (error) {
      handleError(error);
    }
  } catch (error) {
    const statusCode = get(error, ['errorData', 'statusCode']);
    if (statusCode === 403) {
      return false;
    }
    if (statusCode === 401) {
      return false;
    }
    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const createHashLink = id => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();
  try {
    const options = {
      method: 'POST',
      url: `/api/interview/interview-result/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      data: {},
    };

    await dispatch(updateInterviewState({loading: true}));

    // eslint-disable-next-line no-unused-vars
    const {data} = await axios(options);

    await dispatch(
      updateInterviewState({
        loading: false,
      }),
    );
  } catch (error) {
    await dispatch(updateInterviewState({loading: false}));

    await dispatch(
      addErrorNotification({
        title: get(error, ['errorData', 'statusCode']),
        message: get(error, ['errorData', 'message']),
      }),
    );
  }
};

export const addNewInterviewResult = values => (dispatch, getState) => {
  const {
    interviewer: {interviewResult},
  } = getState();
  dispatch(updateInterviewState({interviewResult: [...interviewResult, values]}));
  history.push('/data/all_staff');
};

export const defaultState = {
  interviewersList: [],
  interviewResult: [],
  loading: false,
};

export const resetinterviewState = () => dispatch => dispatch(updateInterviewState(defaultState));

export const updateInterviewState = createAction('UPDATE_INTERVIEW_STATE');

export const reducer = handleActions(
  {
    [updateInterviewState]: reducerMethods.update,
  },
  defaultState,
);
