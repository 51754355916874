import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {makeStyles} from '@material-ui/core/styles';

import {AppBar, Typography, Toolbar as MaterialToolbar, Button} from '@material-ui/core';

import {ExitToApp as ExitToAppIcon} from '@material-ui/icons';

import {routes} from '../constants/routes';
import {deleteAuthToken} from '../modules/utils';
import {useLocation} from 'react-router-dom';

const useStyles = makeStyles({
    AppBar: {
        zIndex: 1000
    },
    IconButton: {
        color: 'white'
    }
});

const Title = styled(Typography)`
    && {
        margin-left: 10px;
        font-weight: bold;
    }
`;

const Toolbar = styled(MaterialToolbar)`
    && {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

const LineBlock = styled.div`
    display: flex;
    align-items: center;
`;

const ButtonStyled = styled(Button)`
    color: white !important;
`;

export const Header = ({children}) => {
    const classes = useStyles();
    const {userInfo} = useSelector(state => state.auth);
    const location = useLocation();

    const handleOnExit = () => {
        deleteAuthToken();
        window.location.href = routes.main;
    };

    const interviewer = useMemo(() => {
        if (location?.pathname.includes('/data/interviewer')) {
            return 'Интервьюер';
        }
        return '';
    }, [location]);

    return (
        <AppBar position="sticky" className={classes.AppBar}>
            <Toolbar>
                <LineBlock>
                    {userInfo?.role === 'Manager' && <Title type="title">Менеджер</Title>}
                    {userInfo?.role === 'Recruter' && <Title type="title">Рекрутер</Title>}
                    {interviewer && <Title type="title">{interviewer}</Title>}
                </LineBlock>
                <LineBlock>{children}</LineBlock>
                <LineBlock>
                    <ButtonStyled startIcon={<ExitToAppIcon />} onClick={handleOnExit}>
                        Выход
                    </ButtonStyled>
                </LineBlock>
            </Toolbar>
        </AppBar>
    );
};
