import axios from 'axios';
import {createAction, handleActions} from 'redux-actions';
import get from 'lodash/get';
import {reducerMethods} from './redux';
import {handleError} from '../lib/lib/handle-error';
import {addErrorNotification, addSuccessNotification} from './notifications';

export const getMaterialFiles = () => async (dispatch, getState) => {
    const {
        auth: {
            tokenInfo: {access_token}
        }
    } = getState();
    try {
        const options = {
            method: 'GET',
            url: '/api/manager-employee/file',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`
            }
        };

        await dispatch(
            updateMaterialsState({
                filesList: [],
                loading: true
            })
        );

        const {data} = await axios(options);

        await dispatch(
            updateMaterialsState({
                filesList: [...data],
                loading: false
            })
        );
    } catch (error) {
        handleError(error);
    }
};

export const getFileSections = () => async (dispatch, getState) => {
    const {
        auth: {
            tokenInfo: {access_token}
        }
    } = getState();
    try {
        const options = {
            method: 'GET',
            url: '/api/edu-file-section',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`
            }
        };

        await dispatch(
            updateMaterialsState({
                fileSectionsList: [],
                loading: true
            })
        );

        const {data} = await axios(options);

        await dispatch(
            updateMaterialsState({
                fileSectionsList: data,
                loading: false
            })
        );
    } catch (error) {
        handleError(error);
    }
};

export const getFileSection = id => async (dispatch, getState) => {
    const {
        auth: {
            tokenInfo: {access_token}
        }
    } = getState();
    try {
        const options = {
            method: 'GET',
            url: `/api/edu-file-section/${id}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`
            }
        };

        await dispatch(
            updateMaterialsState({
                loading: true
            })
        );

        const {data} = await axios(options);

        await dispatch(
            updateMaterialsState({
                selectedSection: data,
                loading: false
            })
        );
    } catch (error) {
        handleError(error);
    }
};

export const deleteFileSection = id => async (dispatch, getState) => {
    const {
        auth: {
            tokenInfo: {access_token},
        },
    } = getState();
    try {
        const options = {
            method: 'DELETE',
            url: `/api/edu-file-section/${id}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`,
            },
        };

        await dispatch(
            updateMaterialsState({
                loading: true,
            }),
        );

        // eslint-disable-next-line no-unused-vars
        const {data} = await axios(options);

        await dispatch(getFileSections());

        await dispatch(
            updateMaterialsState({
                loading: false,
            }),
        );
    } catch (error) {
        handleError(error);
        await dispatch(
            updateMaterialsState({
                loading: false,
            }),
        );
    }
};

export const createFileSection = values => async (dispatch, getState) => {
    const {
        auth: {
            tokenInfo: {access_token}
        }
    } = getState();
    try {
        const options = {
            method: 'POST',
            url: '/api/edu-file-section',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`
            },
            data: values
        };

        await dispatch(
            updateMaterialsState({
                loading: true
            })
        );

        const {data} = await axios(options);

        await dispatch(getFileSections());

        await dispatch(
            updateMaterialsState({
                selectedSection: data,
                loading: false
            })
        );
    } catch (error) {
        handleError(error);
        await dispatch(
            updateMaterialsState({
                loading: false
            })
        );
    }
};

export const updateFileSection = (id, values) => async (dispatch, getState) => {
    const {
        auth: {
            tokenInfo: {access_token}
        },
        materials: {fileSectionsList, blockId, areaId, teamId}
    } = getState();
    try {
        const normalizeValues = {
            ...values,
            teams: values.teams || teamId ? values.teams || teamId : [],
            areas: values.areas || areaId ? values.areas || areaId : [],
            blocks: values.blocks || blockId ? values.blocks || blockId : []
        };

        const options = {
            method: 'PUT',
            url: `/api/edu-file-section/${id}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`
            },
            data: normalizeValues
        };

        await dispatch(
            updateMaterialsState({
                loading: true
            })
        );

        const {data} = await axios(options);

        await dispatch(
            updateMaterialsState({
                selectedSection: data,
                fileSectionsList: [...fileSectionsList],
                loading: false
            })
        );
    } catch (error) {
        handleError(error);
        await dispatch(
            updateMaterialsState({
                loading: false
            })
        );
    }
};

export const uploadMaterialFile = source => async (dispatch, getState) => {
    try {
        const {
            auth: {
                tokenInfo: {access_token}
            },
            materials: {filesList, selectedSection}
        } = getState();

        const params = {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        };

        const file = new Blob([source], {type: source.type});
        const formData = new FormData();

        formData.append('name', `{"ru": "${source.name}"}`);
        formData.append('file', file);

        if (selectedSection) {
            formData.append('section', `{"id": ${selectedSection?.id}}`);
        }

        try {
            await dispatch(updateMaterialsState({loading: true}));

            const {data} = await axios.post('/api/manager-employee/file', formData, params);

            await dispatch(
                updateMaterialsState({
                    loading: false,
                    filesList: [...filesList, data]
                })
            );
            await dispatch(
                addSuccessNotification({
                    title: 'Успех',
                    message: 'Файл успешно добавлен'
                })
            );
        } catch (error) {
            await dispatch(
                addErrorNotification({
                    title: 'Ошибка',
                    message: 'Превышен максимальный размер файла'
                })
            );
            await dispatch(updateMaterialsState({loading: false}));
        }
    } catch (error) {
        await dispatch(updateMaterialsState({loading: false}));

        await dispatch(
            addErrorNotification({
                title: 'Ошибка',
                message: 'Превышен максимальный размер файла'
            })
        );
    }
};

// eslint-disable-next-line require-await
export const uploadAllFiles = files => async (dispatch, getState) => {
    const {
        auth: {
            tokenInfo: {access_token},
        },
        materials: {
            // filesList,
            // blockId,
            // areaId,
            // teamId,
            // materialsType,
            selectedSection,
        },
    } = getState();

    const params = {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    };
    if (files.length) {
        files.forEach(async source => {
            const file = new Blob([source], {type: source.type});
            const formData = new FormData();

            formData.append('name', `{"ru": "${source.name}"}`);
            formData.append('file', file);

            if (selectedSection) {
                formData.append('section', `{"id": ${selectedSection?.id}}`);
            }

            try {
                // eslint-disable-next-line no-unused-vars
                const {data} = await axios.post(
                    '/api/manager-employee/file',
                    formData,
                    params,
                );

                await dispatch(updateMaterialsState({loading: true}));
                setTimeout(() => {
                    dispatch(getMaterialFiles());
                    dispatch(updateMaterialsState({loading: false}));
                    dispatch(
                        addSuccessNotification({
                            title: 'Успех',
                            message: 'Файлы успешно добавлены',
                        }),
                    );
                }, 2000);
            } catch (error) {
                await dispatch(
                    addErrorNotification({
                        title: 'Ошибка',
                        message: 'Превышен максимальный размер файла',
                    }),
                );
                await dispatch(updateMaterialsState({loading: false}));
            }
        });
    }
};

export const uploadMaterialVideoFile = source => async (dispatch, getState) => {
    try {
        const {
            auth: {
                tokenInfo: {access_token}
            },
            materials: {filesList, selectedSection}
        } = getState();

        const params = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`
            }
        };

        if (selectedSection) {
            source.section = {id: selectedSection?.id};
        }

        try {
            await dispatch(updateMaterialsState({loading: true}));

            const {data} = await axios.post('/api/edu-file/video', source, params);

            await dispatch(
                addSuccessNotification({
                    title: 'Успех',
                    message: 'Видео фаил успешно добавлен'
                })
            );

            await dispatch(
                updateMaterialsState({
                    loading: false,
                    filesList: [...filesList, data]
                })
            );
        } catch (error) {
            await dispatch(
                addErrorNotification({
                    title: 'Ошибка',
                    message: 'Превышен максимальный размер файла'
                })
            );
            await dispatch(updateMaterialsState({loading: false}));
        }
    } catch (error) {
        await dispatch(updateMaterialsState({loading: false}));

        await dispatch(
            addErrorNotification({
                title: 'Ошибка',
                message: 'Превышен максимальный размер файла'
            })
        );
    }
};

export const deleteMaterialFile = fileId => async (dispatch, getState) => {
    try {
        const {
            auth: {
                tokenInfo: {access_token}
            },
            materials: {filesList}
        } = getState();

        const url = `/api/manager-employee/file/${fileId}`;

        const params = {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        };

        try {
            await dispatch(updateMaterialsState({loading: true}));

            await axios.delete(url, params);

            await dispatch(
                updateMaterialsState({
                    loading: false,
                    filesList: filesList.filter(({id}) => id !== fileId)
                })
            );
        } catch (error) {
            handleError(error);
        }
    } catch (error) {
        await dispatch(updateMaterialsState({loading: false}));

        await dispatch(
            addErrorNotification({
                title: get(error, ['errorData', 'statusCode']),
                message: get(error, ['errorData', 'message'])
            })
        );
    }
};

export const resetSelectSection = () => dispatch => {
    dispatch(updateMaterialsState({selectedSection: null}));
};

const materialsDefault = {
    materialsType: 'general',
    filesList: [],
    fileSectionsList: [],
    selectedSection: null,
    loadingProgress: null,
    loading: false,
    blockId: null,
    areaId: null,
    teamId: null
};

export const defaultState = materialsDefault;
export const updateMaterialsState = createAction('UPDATE_MATERIALS_STATE');

export const resetState = () => dispatch => dispatch(updateMaterialsState(materialsDefault));

export const reducer = handleActions(
    {
        [updateMaterialsState]: reducerMethods.update
    },
    defaultState
);
