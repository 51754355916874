import axios from 'axios';
import {createAction, handleActions} from 'redux-actions';
import {reducerMethods} from './redux';
import {handleError} from '../lib/lib/handle-error';
import {addErrorNotification} from './notifications';

export const getAttendanceApplications = filters => async (dispatch, getState) => {
  const {
    auth: {
      tokenInfo: {access_token},
    },
  } = getState();

  const fields = ['id,lastName,firstName,patronymic,birthday,', 'citizenship,email,photo,', 'application.manager,languages,application.status'].join(
    '',
  );

  const params = {
    sort: '',
    page: 1,
    limit: 999999,
    fields,
  };

  try {
    const options = {
      method: 'GET',
      url: '/api/manager-employee/list',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    };

    const promiseArr = Object.entries(filters).map(async ([key, value]) => {
      if (value) {
        const {
          data: {data},
        } = await axios({...options, params: {...params, [key]: value}});
        return data;
      }
      return [];
    });

    const totalData = await Promise.all(promiseArr).then(data => {
      return data.reduce((result, item) => {
        return result.concat(item);
      }, []);
    });

    await dispatch(
      updateAttendanceState({
        attendanceList: totalData,
      }),
    );
  } catch (error) {
    handleError(error);
  }
};

export const defaultState = {
  attendanceList: [],
};

export const resetAttendanceState = () => dispatch => dispatch(updateAttendanceState(defaultState));

export const updateAttendanceState = createAction('UPDATE_ATTENDANCE_STATE');

export const reducer = handleActions(
  {
    [updateAttendanceState]: reducerMethods.update,
  },
  defaultState,
);
