import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Typography} from '@material-ui/core';
// Import EuroLogo from '../../statics/euroskills.svg';

const Conatainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-image: url('/statics/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    position: relative;
`;

const Label = styled.div`
    margin-left: 15px;
    top: 20px;
    text-align: center;
`;

/*
 * Const ImgEuroLogo = styled.img`
 *   position: absolute;
 *   top: 0;
 *   right: 36px;
 *   width: 127px;
 * `;
 */

export const MainScreen = ({children}) => (
    <Conatainer>
        {/* <ImgEuroLogo src={EuroLogo} alt='euro-skills' /> */}
        <Label>
            <Typography color="primary" variant="h3" gutterBottom>
                Менеджер / Рекрутер
            </Typography>
        </Label>
        {children}
    </Conatainer>
);

MainScreen.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

MainScreen.defaultProps = {
    children: null
};
