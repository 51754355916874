const get = require('lodash/get');
const has = require('lodash/has');

const errorMessages = {
    Unauthorized: 'Неверный логин или пароль'
};
const handleError = error => {
    const errorData = {
        code: '',
        message: ''
    };

    if (has(error, ['response', 'data', 'message'])) {
        const {statusCode, message} = get(error, ['response', 'data']);
        errorData.statusCode = statusCode;
        errorData.message = errorMessages[message] || message;
    } else {
        errorData.code = 'error';
        errorData.message = 'upsError';
    }

    error.errorData = errorData;

    throw error;
};

module.exports = {handleError};
