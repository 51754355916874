import {createAction, handleActions} from 'redux-actions';
import {reducerMethods} from './redux';

const {pathname, search} = window.location;

export const updateRouteAction = createAction('UPDATE_ROUTE_STATE');

export const defaultState = {
    path: `${pathname}${search}`
};

export const reducer = handleActions(
    {
        [updateRouteAction]: reducerMethods.update
    },
    defaultState
);
