import {createAction, handleActions} from 'redux-actions';
import {reducerMethods} from './redux';

export const defaultState = {
  zoneList: [],
  shiftMap: {},
  isLoading: false,
};

export const updateScheduleStoreState = createAction('UPDATE_SCHEDULE_STORE_STATE');

export const reducer = handleActions(
  {
    [updateScheduleStoreState]: reducerMethods.update,
  },
  defaultState,
);
