import {routes} from './routes';

export const generalMenuData = {
  all_staff: {
    title: 'Все',
    path: routes.all_staff,
  },
  volunteers: {
    title: 'Волонтеры',
    path: routes.volunteers,
  },
  temporary_staff: {
    title: 'Временный персонал',
    path: routes.temporary_staff,
  },
  materials: {
    title: 'Материалы',
    path: routes.materials,
  },
  news: {
    title: 'Новости',
    path: routes.news,
  },
  tests: {
    title: 'Тесты',
    path: routes.tests,
  },
  interview: {
    title: 'Собеседование',
    path: routes.interview,
  },
  schedule: {
    title: 'Расписание',
    path: routes.schedule,
  },
  teams: {
    title: 'Команды',
    path: routes.teams,
  },
  attendance: {
    title: 'Учет посещаемости',
    path: routes.attendance,
  },
};
