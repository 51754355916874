import {combineReducers, createStore, applyMiddleware} from 'redux';

import {reducer as form} from 'redux-form';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import {reducer as reactNotifications} from 'react-notification-system-redux';
import {createBrowserHistory} from 'history';
import {syncHistoryWithStore, routerMiddleware, routerReducer} from 'react-router-redux';
import * as Global from './global';
import * as Auth from './auth';
import * as Staff from './staff';
import * as Materials from './materials';
import * as News from './news';
import * as Utils from './utils';
import * as Notifications from './notifications';
import * as TestsState from './tests';
import * as LogsState from './logs';
import * as Interviewer from './interviewer';
import * as InterviewerList from './interviewerList';
import * as ScheduleStore from './scheduleStore';
import * as Attendance from './attendance';
import * as Schedule from './schedule';
import * as Router from './router';
import * as Directories from './directories';

// const defaultState = {
//     global: Global.defaultState,
//     auth: Auth.defaultState,
//     staff: Staff.defaultState,
//     materials: Materials.defaultState,
//     news: News.defaultState,
//     utils: Utils.defaultState,
//     tests: TestsState.defaultState,
//     notifications: [],
//     interviewer: Interviewer.defaultState,
//     schedule: Schedule.defaultState,
//     notificationsCustom: Notifications.defaultState,
//     router: Router.defaultState
// };

const rootReducer = combineReducers({
  routing: routerReducer,
  form,
  global: Global.reducer,
  auth: Auth.reducer,
  staff: Staff.reducer,
  materials: Materials.reducer,
  news: News.reducer,
  utils: Utils.reducer,
  notifications: reactNotifications,
  notificationsCustom: Notifications.reducer,
  tests: TestsState.reducer,
  logs: LogsState.reducer,
  interviewer: Interviewer.reducer,
  interviewerList: InterviewerList.reducer,
  scheduleStore: ScheduleStore.reducer,
  schedule: Schedule.reducer,
  router: Router.reducer,
  attendance: Attendance.reducer,
  directories: Directories.reducer,
});

const middlewares = [thunk, routerMiddleware(createBrowserHistory())];

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));

export const history = syncHistoryWithStore(createBrowserHistory(), store);
