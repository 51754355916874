import {isNaN, isNumber, isString} from 'lodash';

export function isNullable(value) {
  return value === null || value === undefined;
}

export function isNotNullable(value) {
  return !isNullable(value);
}

export function isObject(value) {
  return isNotNullable(value) && !Array.isArray(value) && !isNaN(value) && typeof value === 'object';
}

export const isEmptyObject = value => !Object.keys(value ?? {}).length;

export const isNotEmpty = value => {
  if (isString(value)) {
    return !!value?.trim();
  }

  if (Array.isArray(value)) {
    return !!value.length;
  }

  if (isNaN(value)) {
    return false;
  }

  if (isNumber(value)) {
    return true;
  }

  if (isObject(value)) {
    return !isEmptyObject(value);
  }

  return isNotNullable(value);
};

export const isEmpty = value => !isNotEmpty(value);

export const notNullableParams = value => {
  if (value) {
    return Object.entries(value).reduce((result, [key, value]) => {
      if (value) {
        result[key] = value;
      }
      return result;
    }, {});
  }
  return {};
};
